
import {
  defineComponent,
  computed,
  reactive,
  watch,
} from "@vue/composition-api";
import merge from "lodash/merge";

import usePrinter from "@/use/templates/printer";
import { useVModel } from "@vueuse/core";

export default defineComponent({
  props: {
    value: { type: Boolean, required: false, default: undefined },
    image: { type: String, required: false, default: undefined },
    config: { type: Object, required: false, default: undefined },
  },
  setup(props, { emit }) {
    const {
      isActive,
      printers,
      selectedPrinter,
      selectPrinter,
      connect,
      disconnect,
      printImage,
    } = usePrinter();

    const model = useVModel(props, "value", emit, { eventName: "input" });

    const open = () => (model.value = true);
    const close = () => (model.value = false);
    const toggle = () => (model.value ? close() : open());

    const image = computed(() => props.image ?? "");

    const config = computed(() =>
      merge(props.config, {
        orientation: "vertical",
        size: { width: 8.27, height: 11.69 },
        density: 300,
        units: "in",
        jobName: "Drukowanie",
      })
    );

    const state = reactive({
      printed: false,
      error: false,
    });

    const reset = () => {
      state.printed = false;
      state.error = false;
    };

    watch(model, (model) => (model ? void 0 : reset()));

    const print = () => {
      printImage(image.value, config.value)
        .then(() => {
          state.printed = true;
          state.error = false;
        })
        .catch(() => {
          state.printed = false;
          state.error = true;
        });
    };

    return {
      isActive,
      printers,
      selectedPrinter,
      selectPrinter,
      connect,
      disconnect,
      model,
      open,
      close,
      toggle,
      state,
      reset,
      print,
    };
  },
});
